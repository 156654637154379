import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Otp from '../components/Otp';
import { getQueryVariable } from "../state/utils";

const OtpPage: React.FC = (props: any) => {
  const token = getQueryVariable('token', props.location)
  return (
    <Layout page='otp'>
        <SEO title="OTP" />
        <Otp token={token}/>
    </Layout>
  );
};

export default OtpPage;
