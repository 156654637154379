import React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';

import Container from 'components/ui/Container';
import * as Styled from './styles';
import { verifyOtp } from '../../state/user/user.service';
import { useI18next } from "gatsby-plugin-react-i18next";

const Otp = ({ token }) => {
  const { navigate } = useI18next();
  return (
    <Container section>
      <Styled.Wrapper>
        <Styled.Mob>
          <Styled.MobileTtitle>Enter OTP</Styled.MobileTtitle>
        </Styled.Mob>
        <br />
        <Formik
          initialValues={{ otp: '' }}
          validate={(values) => {
            const errors = {};
            if (!values.otp) {
              errors.otp = 'Verification code is required';
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const verifyOtpResponse = await verifyOtp(values.otp, token)
              .toPromise()
              .catch((err) => {
                if (err.response.statusCode === 409) {
                  alert('Verification Expired');
                } else if (err.response.statusCode === 401) {
                  alert('Invalid Code');
                } else {
                  alert(err.response.error);
                }
              });

            await navigate(`/set-password/?token=${verifyOtpResponse.token}`);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Styled.Text small>Verification Code</Styled.Text>
              <Field type="number" name="otp">
                {({ field, meta: { touched, error } }) => (
                  <input
                    className={touched && error ? 'bg-red-100 border border-red-100' : ''}
                    {...field}
                  />
                )}
              </Field>
              <ErrorMessage name="otp" component="div" className="mt-1 text-xs text-red-600" />
              <Styled.Button type="submit" disabled={isSubmitting} className="bg-gray-200 w-full">
                Submit
              </Styled.Button>
            </Form>
          )}
        </Formik>
      </Styled.Wrapper>
    </Container>
  );
};

export default Otp;
